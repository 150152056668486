import React, { useCallback, useEffect, useMemo, useState } from "react";
import { QuizLanding } from "./components/QuizLanding";
import QuizQuestions from "./components/QuizQuestions";
import { QuizResults } from "./components/QuizResults";
import QuizSetup from "./components/QuizSetup";
import "./QuizApp.scss";

export interface QuizSetupForm {
  name?: string;
  surname?: string;
  company: string;
  email: string;
  food: "pizza" | "sushi";
}

export interface QuizQuestion {
  dinnerWith: string;
  zodiac: string;
  diet: string;
  inKitchen: string;
  inHoliday: string;
  favorite: string;
}

export type Result =
  | "OSTRICHE"
  | "CAPONATA"
  | "TARTARE"
  | "PAELLA"
  | "FRISELLE"
  | "COSTINE";

const findMostFrequent = (results: Result[]): Result | null => {
  const countMap: Record<Result, number> = {
    OSTRICHE: 0,
    CAPONATA: 0,
    TARTARE: 0,
    PAELLA: 0,
    FRISELLE: 0,
    COSTINE: 0,
  };

  // Conteggio delle occorrenze di ciascun elemento nell'array
  results.forEach((result) => {
    countMap[result] = (countMap[result] || 0) + 1;
  });

  // Trovare l'elemento più ricorrente
  let mostFrequent: Result | null = null;
  let maxCount = 0;

  Object.entries(countMap).forEach(([result, count]) => {
    if (count > maxCount) {
      mostFrequent = result as Result;
      maxCount = count;
    }
  });

  return mostFrequent;
};

export const QuizApp = () => {
  const [quizState, setQuizState] = useState("landing"); // 'landing', 'quiz', 'result'
  const [quizSetup, setQuizSetup] = useState<QuizSetupForm | null>(null);
  const [quizAnswers, setQuizAnswers] = useState<QuizQuestion | null>(null);
  const [resultScore, setResultScore] = useState<Array<Result>>([]);
  const [result, setResult] = useState<Result | null>(null);

  const setupQuiz = () => {
    setQuizState("setup");
  };

  const finalScore = useMemo(() => {
    return findMostFrequent(resultScore);
  }, [resultScore]);

  useEffect(() => {
    // nascondi l'emento con id head (display: none)
    document.getElementById("head")?.classList.add("hidden");
    // non permetettere di andare indietro
    window.history.pushState(null, "", window.location.href);
    if (finalScore) {
      setResult(finalScore);
      saveResultOnDB(
        quizSetup?.name || "",
        quizSetup?.surname || "",
        quizSetup?.email || "",
        quizAnswers!,
        finalScore
      );
    }
  }, [
    finalScore,
    quizAnswers,
    quizSetup?.email,
    quizSetup?.name,
    quizSetup?.surname,
    resultScore,
  ]);

  const saveResultOnDB = (
    fname: string,
    lname: string,
    email: string,
    questions: QuizQuestion,
    finalRes: Result
  ) => {
    // make a post request to the server passing it email, all the answers and the result
    fetch("https://time2livery.com/receipe/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fname: fname,
        lname: lname,
        email: email,
        chooses: JSON.stringify(questions),
        result: JSON.stringify(finalRes),
      }),
    });
  };

  const startQuiz = (input: QuizSetupForm) => {
    setQuizState("quiz");
    console.log(input);
    setQuizSetup(input);
    window.scrollTo(0, 0);
  };

  const showResult = (input: QuizQuestion) => {
    setQuizAnswers(input);
    switch (input.dinnerWith) {
      case "1": // gerry
        setResultScore((prev) => [...prev, "CAPONATA", "COSTINE"]);
        break;
      case "2": // matteo
        setResultScore((prev) => [...prev, "TARTARE", "PAELLA"]);
        break;
      case "3": // luca
        setResultScore((prev) => [...prev, "OSTRICHE", "FRISELLE"]);
        break;
      default:
        break;
    }
    switch (input.zodiac) {
      case "1": // aria
        setResultScore((prev) => [...prev, "CAPONATA", "FRISELLE"]);
        break;
      case "2": // terra
        setResultScore((prev) => [...prev, "TARTARE", "COSTINE"]);
        break;
      case "3": // acqua
        setResultScore((prev) => [...prev, "OSTRICHE", "PAELLA"]);
        break;
      default:
        break;
    }
    switch (input.diet) {
      case "1": // vegano
        setResultScore((prev) => [...prev, "CAPONATA", "FRISELLE"]);
        break;
      case "2": // onnivoro
        setResultScore((prev) => [
          ...prev,
          "TARTARE",
          "PAELLA",
          "OSTRICHE",
          "COSTINE",
          "FRISELLE",
          "CAPONATA",
        ]);
        break;
      case "3": // solo pesce
        setResultScore((prev) => [...prev, "OSTRICHE", "PAELLA"]);
        break;
      default:
        break;
    }
    switch (input.inKitchen) {
      case "1": // alla griglia
        setResultScore((prev) => [...prev, "COSTINE"]);
        break;
      case "2": // ai fornelli
        setResultScore((prev) => [...prev, "TARTARE", "PAELLA"]);
        break;
      case "3": // sul divano
        setResultScore((prev) => [...prev, "OSTRICHE", "FRISELLE", "CAPONATA"]);
        break;
      default:
        break;
    }
    switch (input.inHoliday) {
      case "1": // in montagna
        setResultScore((prev) => [...prev, "COSTINE", "TARTARE"]);
        break;
      case "2": // al mare
        setResultScore((prev) => [...prev, "PAELLA", "OSTRICHE"]);
        break;
      case "3": // in città
        setResultScore((prev) => [...prev, "CAPONATA", "FRISELLE"]);
        break;
      default:
        break;
    }
    switch (input.favorite) {
      case "1": // trattoria
        setResultScore((prev) => [...prev, "CAPONATA", "FRISELLE"]);
        break;
      case "2": // stellato
        setResultScore((prev) => [...prev, "TARTARE", "OSTRICHE"]);
        break;
      case "3": // bistrot
        setResultScore((prev) => [...prev, "PAELLA", "COSTINE"]);
        break;
      default:
        break;
    }
    setQuizState("result");
    window.scrollTo(0, 0);
  };

  // Altri metodi e logica necessari

  return (
    <div id="quiz-container">
      {quizState === "landing" && <QuizLanding onSetupQuiz={setupQuiz} />}
      {quizState === "setup" && <QuizSetup onSetupQuix={startQuiz} />}
      {quizState === "quiz" && <QuizQuestions onShowResult={showResult} />}
      {quizState === "result" && <QuizResults result={result} />}
    </div>
  );
};
