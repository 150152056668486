import React from "react";
import { Link } from "react-router-dom";

export interface FooterProps {
  image?: "home" | "cappello" | "ice" | "pizza";
}

export default function Footer(props: FooterProps) {
  return (
    <>
      <div className="mt-[20vw] md:mt-[20vh] ">
        <img
          src={`images/footer/footer-${props.image}.png`}
          alt="footer"
          className=" mx-[23%] md:mx-[33%] md:w-[30%] w-[50%] "
        />
        <div className="items-center md:flex justify-between text-white green-bg p-[5px] md:p-[30px] mt-[-30px] md:mt-[-70px]  ">
          <div className="flex flex-col md:items-start items-center flex-1 md:text-start text-center md:mt-0 mt-[30px] md:mb-0 mb-[20px] gap-3">
            <h1 className="text-[13px] md:text-[25px] font-bold ">CONTATTI</h1>
            <ul className="footer-info">
              <a href="https://www.google.com/maps/dir//Bonsai+Web+Agency,+Via+Giuseppe+Giusti,+209,+50041+Calenzano+FI/@43.8599072,11.1687879,743m/data=!3m1!1e3!4m8!4m7!1m0!1m5!1m1!1s0x132af96cdd573791:0x39555c6576673c8f!2m2!1d11.1734031!2d43.8599092?hl=en&entry=ttu">
                <li className=" text-[13px] md:text-[20px]">
                  <i className="fa fa-map-pin"></i> Via G. Giusti 209 Calenzano
                  (FI) - 50142
                </li>
              </a>
              <a href="tel:3337642706">
                <li className="md:mt-5 text-[13px] md:text-[20px]">
                  <i className="fa fa-phone"></i> 333 7642706
                </li>
              </a>
              <a href="mailto:info@bonsai-firenze.com">
                <li className="md:mt-5 text-[13px] md:text-[20px]">
                  <i className="fa fa-envelope"></i> info@bonsai-firenze.com
                </li>
              </a>
              <li className="md:mt-5 text-[13px] md:text-[20px]">
                Bonsai Web Agency © 2024
              </li>
            </ul>
          </div>
          <div className="flex-1 ">
            <img
              src="../../images/logobonsai_white.png"
              className=" md:w-[190px] w-[130px] mx-auto"
              alt="logo"
            />
          </div>
          <div className="md:text-right text-center flex flex-1 md:flex-col flex-row md:items-end items-center justify-evenly md:my-0 my-[20px]">
            <div className="">
              <h1 className="text-[13px] md:text-[25px] font-bold">
                HAI UN'IDEA?
              </h1>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="underline text-[10.5px] md:text-[20px]"
                to="/project"
              >
                Scrivici
              </Link>
            </div>

            <div>
              <h1 className="text-[13px] md:text-[25px] font-bold ">
                LAVORA CON NOI
              </h1>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="underline text-[10px] md:text-[20px]"
                to="/workWithUs"
              >
                Scrivici
              </Link>
            </div>
            <div>
              <h1 className="text-[13px] md:text-[25px] font-bold ">
                SEGUICI{" "}
              </h1>
              <a
                href="https://www.facebook.com/bonsaiwebagency"
                className="mx-[5px]"
              >
                <i className="fa fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/bonsai_firenze/"
                className="mx-[5px]"
              >
                <i className="fa fa-instagram"></i>
              </a>
              {/* <a
                href="https://www.linkedin.com/company/bonsai-firenze/"
                className="mx-[5px]"
              >
                <i className="fa fa-linkedin"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center flex-wrap gap-[10px] green-bg pb-[10px]">
        <div>
          <a
            href="https://www.iubenda.com/privacy-policy/39177243"
            className="iubenda-noiframe iubenda-embed iubenda-noiframe mx-[5px]"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
        </div>
        <div>
          <a
            href="https://www.iubenda.com/privacy-policy/39177243/cookie-policy"
            className="iubenda-noiframe iubenda-embed iubenda-noiframe mx-[5px] "
            title="Cookie Policy "
          >
            Cookie Policy
          </a>
        </div>
      </div>
      <p className="text-white green-bg text-[8px] md:text-[10px] text-center pb-[10px] md:px-[20%] px-[5%]">
        M.B.A. S.R.L. Via Panciatichi 26/3 Firenze CAP 50127 REA FI - 658013 -
        inscrizione registro imprese Firenze n. 06810160488 P.I. 06810160488 -
        Capitale Sociale 10.000,00 € i.v. - PEC mbasrl@peceasy.it
      </p>
    </>
  );
}
