import React, { useEffect, useRef } from "react";

export default function FlagBanner() {
  return (
    <div className="flag-banner flex items-center justify-center green-bg mt-10 text-center text-white banner-title  md:h-[150px] h-[150px] ">
      <div className="waviy md:text-[60px] text-[15px]">
        <span className="w1">I</span>
        <span className="w2">L </span>
        <span className="w3"> </span>
        <span className="w4 ml-4">T</span>
        <span className="w5">U</span>
        <span className="w6">O </span>

        <span className="w7"></span>
        <span className="w8 ml-4">P</span>
        <span className="w9">A</span>
        <span className="w10">R</span>
        <span className="w11">T</span>
        <span className="w12">N</span>
        <span className="w13">E</span>
        <span className="w14">R </span>
        <span className="w15"> </span>
        <span className="w16 ml-4">A </span>
        <span className="w17"> </span>
        <span className="w18 ml-4">T</span>
        <span className="w19">U</span>
        <span className="w20">T</span>
        <span className="w21">T</span>
        <span className="w22">O </span>
        <span className="w23"> </span>
        <span className="w24 ml-4">P</span>
        <span className="w25">I</span>
        <span className="w26">A</span>
        <span className="w27">T</span>
        <span className="w28">T</span>
        <span className="w29">O </span>
        <span className="w30"> </span>
      </div>
    </div>
  );
}
