import { animated, useSpring } from "@react-spring/web";
import { useEffect, useState } from "react";

export const QuizLanding = ({ onSetupQuiz }: { onSetupQuiz?: () => void }) => {
  // stato per la gestione dell'animazione
  const [exited, setExited] = useState(false);

  const onClick = () => {
    setExited(true);

    const startQuiz = setTimeout(() => {
      onSetupQuiz && onSetupQuiz();
    }, 400);

    return () => {
      clearTimeout(startQuiz);
    };
  };

  const props = useSpring({
    // Define the animation properties
    opacity: exited ? 0 : 1,
    transform: exited ? "scale(1.5)" : "scale(1)",
  });

  return (
    <animated.div
      onClick={onClick}
      style={props}
      className={
        "flex flex-col flex-1 items-center justify-evenly p-[30px] h-[90vh]"
      }
    >
      <img
        src="../../../images/quiz/logo.png"
        alt="Logo Bonsai Firenze Web Agency"
        width={180}
        height={180}
      />
      <img
        src="../../../images/quiz/piatto_sporco.png"
        alt="Piatto sporco"
        width={240}
        height={240}
      />
    </animated.div>
  );
};
