import React, { useCallback } from "react";

import Footer from "../../components/Footer";
import { on } from "events";

const pages = [<div>Pagina 1</div>, <div>Pagina 2</div>, <div>Pagina 3</div>];

const API_KEY =
  "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";

/*
  curl --request POST \
  --url https://api.sendgrid.com/v3/mail/send \
  --header "Authorization: Bearer $SENDGRID_API_KEY" \
  --header 'Content-Type: application/json' \
  --data '{"personalizations": [{"to": [{"email": "test@example.com"}]}],"from": {"email": "test@example.com"},"subject": "Sending with SendGrid is Fun","content": [{"type": "text/plain", "value": "and easy to do anywhere, even with cURL"}]}'
  */

export default function ProjectScreen() {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [dish, setDish] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const onSubmit = useCallback(() => {
    window.scrollTo(0, 500);
    if (!name || !validateEmail(email) || !dish) {
      console.log(name, validateEmail(email), dish);
      setShowError(true);
      return;
    }
    const data = {
      name,
      email,
      dish,
    };

    fetch("https://api.sendgrid.com/v3/mail/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_KEY}`,
      },
      body: JSON.stringify({
        personalizations: [
          {
            to: [
              {
                email: "info@time-consulting.it",
              },
            ],
            subject: "La tua idea",
          },
        ],
        from: {
          email: email,
        },
        content: [
          {
            type: "text/plain",
            value: `
            Nome: ${name}
            Email: ${email}
            Ricetta: ${dish}
            `,
          },
        ],
      }),
    });
  }, [name, email, dish]);

  return (
    <>
      <div className="bg-green">
        <img
          src="/images/project-head.png"
          alt="mock"
          className="w-[100%] mx-auto mb-[50px] md:mb-[150px]"
        />
      </div>
      <h1 className="w-full text-center text-[50px] md:text-[70px]">
        IlTuo<span className="green">Turno</span>
      </h1>
      {showError && (
        <p className="text-[#933] text-center underline">
          Compila tutti i campi
        </p>
      )}
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="NOME"
        type="text"
        className="w-[80%] border-b text-center green mx-auto mt-[50px] block placeholder-green text-[30px] md:text-[40px] text-bold rounded-10"
      />
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="E-MAIL"
        type="text"
        className="w-[80%] border-b text-center green mx-auto mt-[50px] block placeholder-green text-[30px] md:text-[40px] text-bold"
      />
      <h1 className="green text-[40px] md:text-[50px] mt-[50px] md:mt-[150px] text-center w-full">
        LA TUA RICETTA
      </h1>
      <textarea
        value={dish}
        onChange={(e) => setDish(e.target.value)}
        className="w-[80%]  text-left p-5 green mx-auto placeholder-[#000] mt-[50px] block md:text-[30px] bg-[#eee] "
        rows={10}
        placeholder="Raccontaci gli ingredienti per realizzare al meglio il tuo progetto..."
      ></textarea>

      <button
        className=" pink-bg px-[50px] rounded-[50px] text-center  mx-auto mt-[50px] block placeholder-green text-[40px] md:text-[60px] text-bold mb-[150px]"
        onClick={onSubmit}
      >
        INVIA
      </button>

      <Footer image="pizza" />
    </>
  );
}
