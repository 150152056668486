import React, { useEffect, useRef, useState } from "react";
import { Link, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import ProjectScreen from "../screens/ProjectScreen/ProjectScreen";
import ServicesScreen from "../screens/ServicesScreen/ServicesScreen";
import WorkWithUsScreen from "../screens/WorkWithUsScreen.tsx/WorkWithUsScreen";
import useComponentVisible from "../hooks/useComponentVisible";
import BurgerMenu from "./burgerMenu/BurgerMenu";
import { QuizApp } from "../screens/Quiz/QuizApp";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref: React.RefObject<HTMLElement>) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function Navbar() {
  const toggleMenu = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  // se il path è quiz, non mostrare header e footer
  useEffect(() => {
    if (window.location.pathname === "/QuizBalnearia") {
      document.getElementById("head")?.classList.add("hidden");
    } else {
      document.getElementById("head")?.classList.remove("hidden");
    }
  }, []);

  return (
    <Router>
      <div id="head">
        <div className="flex bg-green items-center justify-between py-6 h-[120px] md:h-[180px] z-9999">
          <div className="hidden md:block">
            <div className="pink-bg w-[240px] h-12 flex items-center justify-reverse md:justify-end p-6 rounded-r-3xl shadow-xl">
              <p>SPOILER</p>
            </div>
            <p className="text-[#fff] italic text-right text-[15px] text-[italic] mt-2 animate-character ml-5">
              Non siamo un ristorante
            </p>
          </div>

          <div className="md:pr-[150px]">
            <Link to="/">
              <img
                src="../images/logobonsai_white.png"
                className="w-[100px] md:w-[190px]"
                alt="logo"
              />
            </Link>
          </div>
          <BurgerMenu />
        </div>
        <div
          ref={ref}
          id="menu"
          className={`z-9999 absolute p-4 right-[0%] bg-white w-[100%] md:w-[100%] pink-bg rounded-b-[5px]  ${
            isComponentVisible
              ? "top-[120px] md:top-[170px]"
              : "top-[-100vh] md:top-[-100vh]"
          } shadow-md`}
        >
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/services">
              <li>Il nostro menu</li>{" "}
            </Link>
            <Link to="/project">
              <li>La tua idea</li>
            </Link>
            <Link to="/workWithUs">
              <li>Lavora con noi</li>{" "}
            </Link>
          </ul>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/services" element={<ServicesScreen />} />
        <Route path="/project" element={<ProjectScreen />} />
        <Route path="/workWithUs" element={<WorkWithUsScreen />} />
        <Route path="/quizBalnearia" element={<QuizApp />} />
      </Routes>
    </Router>
  );
}
