import { Link } from "react-router-dom";
import "./burgerMenu.scss";

import React, { useEffect } from "react";

export default function BurgerMenu() {
  const [toggleState, setToggleState] = React.useState(false);

  const toggleMenu = () => {
    setToggleState(!toggleState);
  };

  // il menu si deve chiudere sullo scroll
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setToggleState(false);
    });
  }, []);

  return (
    <div className="menu">
      <input
        type="checkbox"
        id="menu-toggle"
        checked={toggleState}
        onClick={toggleMenu}
      />
      <label id="menu-button" className="menu-button" htmlFor="menu-toggle">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </label>
      <div id="site-menu">
        <ul id="site-menu-titles">
          <li>
            <p>
              <Link onClick={toggleMenu} to="/">
                Home
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link onClick={toggleMenu} to="/services">
                Il nostro menu
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link onClick={toggleMenu} to="/project">
                La tua idea
              </Link>
            </p>
          </li>
          <li>
            <p>
              <Link onClick={toggleMenu} to="/workWithUs">
                Lavora con noi
              </Link>
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}
