import React from "react";

import Footer from "../../components/Footer";

const pages = [<div>Pagina 1</div>, <div>Pagina 2</div>, <div>Pagina 3</div>];

export default function WorkWithUsScreen() {
  return (
    <>
      <div className="bg-green">
        <img
          src="/images/wwu-head.png"
          alt="mock"
          className="w-[100%] mx-auto mb-[50px] md:mb-[150px]"
        />
      </div>
      <h1 className="w-full text-center text-[50px] md:text-[70px]">
        Candidatura<span className="green"> Spontanea</span>
      </h1>
      <input
        placeholder="NOME"
        type="text"
        className="w-[80%] border-b text-center green mx-auto mt-[50px] block placeholder-green text-[30px] md:text-[40px] text-bold"
      />
      <input
        placeholder="E-MAIL"
        type="text"
        className="w-[80%] border-b text-center green mx-auto mt-[50px] block placeholder-green text-[30px] md:text-[40px] text-bold"
      />
      <input
        placeholder="PIATTO PREFERITO"
        type="text"
        className="w-[80%] border-b text-center green mx-auto mt-[50px] block placeholder-green text-[30px] md:text-[40px] text-bold"
      />
      <button className=" green-bg px-[50px] rounded-[50px] text-center text-[#fff]  mx-auto block placeholder-green text-[40px] md:text-[40px] text-bold my-[80px]">
        AGGIUNGI CV
      </button>

      <h1 className="green text-[30px] md:text-[4s0px] mt-[50px] md:mt-[100px] text-center w-full">
        "MA PARLIAMO DI TE, <br /> MA PARLIAMO DI TE..."
      </h1>
      <textarea
        className="w-[80%]  text-left p-5 green mx-auto placeholder-[#000] mt-[50px] block md:text-[30px] bg-[#eee]"
        rows={10}
        placeholder="Cosa fai di bello?"
      ></textarea>

      <button className=" pink-bg px-[50px] rounded-[50px] text-center  mx-auto mt-[50px] block placeholder-green text-[40px] md:text-[60px] text-bold mb-[150px]">
        INVIA
      </button>

      <Footer image="cappello" />
    </>
  );
}
