import React from "react";

export default function HeroSection() {
  return (
    <>
      <div className=" h-[400px] md:h-[800px]">
        <video
          className="w-full h-full object-cover relative z-[-1]"
          autoPlay
          loop
          muted
          playsInline
          src="../../../video/hero-video.mp4"
        ></video>
      </div>
    </>
  );
}
