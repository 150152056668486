import { animated } from "@react-spring/web";
import { useCallback, useMemo } from "react";

/*
  | "OSTRICHE"
  | "CAPONATA"
  | "TARTARE"
  | "PAELLA"
  | "FRISELLE"
  | "COSTINE";
  */

export const QuizResults = ({ result }: { result: string | null }) => {
  // Aggiungi animazioni di macchina da scrivere e l'entrata delle immagini

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const titles = {
    OSTRICHE: "OSTRICHE",
    CAPONATA: "CAPONATA",
    TARTARE: "TARTARE M.",
    PAELLA: "PAELLA",
    FRISELLE: "FRISELLE",
    COSTINE: "COSTINE",
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subtitle = {
    OSTRICHE: "Qualcuno ha detto bollicine?",
    CAPONATA: "Sicilia solo andata, grazie!",
    TARTARE: "Sempre eleganti.",
    PAELLA: "Dos los ramatos.",
    FRISELLE: "Lasciatemi cantare, sono un italiano!",
    COSTINE: "Se non lecchi le dita... godi solo a metà!",
  };

  const isStringKeyOfTitles = useCallback(
    (key: string): key is keyof typeof titles => key in titles,
    [titles]
  );

  const isStringKeyOfSubtitle = useCallback(
    (key: string): key is keyof typeof subtitle => key in subtitle,
    [subtitle]
  );

  const title = useMemo(() => {
    if (result === null) {
      return "";
    }
    if (isStringKeyOfTitles(result)) {
      return titles[result];
    }
    return "";
  }, [isStringKeyOfTitles, result, titles]);

  const subTitle = useMemo(() => {
    if (result === null) {
      return "";
    }
    if (isStringKeyOfSubtitle(result)) {
      return subtitle[result];
    }
    return "";
  }, [isStringKeyOfSubtitle, result, subtitle]);

  return (
    <animated.div
      className={"h-[100vh] p-[20px] mb-[100px] flex flex-col items-center "}
      id="quiz-setup"
    >
      <img
        src="../../../images/quiz/logo.png"
        alt="Logo Bonsai Firenze Web Agency"
        width={80}
        height={80}
      />
      <div className={"flex flex-col items-center mt-10"}>
        <h2 className="quiztext text-[30px]"> STASERA </h2>
        <h1 className="quiztext text-[40px]">{title}</h1>
        <img
          src={`../../../images/quiz/result/${result}.png`}
          alt={result ?? "Piatto non trovato"}
          width={"90%"}
        />
      </div>
      <p className={"text-center mt-5 text-[26px]"}>{subTitle}</p>
      <a
        href="https://bonsai-firenze.com"
        className={"submit-button text-center rounded-30"}
      >
        Scopri la nostra brigata
      </a>
    </animated.div>
  );
};
