import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

interface AccordionItemProps {
  title: string;
  imageUrl: string;
  content: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const AccordionItemContainer = styled.div<{ isOpen: boolean }>`
  border: ${(props) => (props.isOpen ? "1px solid #ddd" : "none")};
  padding: 10px;
  width: 50%;
  @media (max-width: 768px) {
    width: 95%;
    margin: 30px auto;
  }
  border-radius: 80px;
  margin: 10px auto; /* Aggiornato il margine */

  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
  }
  height: auto;
`;

const Content = styled.div<{ isOpen: boolean }>`
  max-height: ${(props) => (props.isOpen ? "500px" : "0")};
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  padding: ${(props) => (props.isOpen ? "5%" : "0")};

  p {
    font-size: 18px;
    margin: 10px 0;
    display: ${(props) => (props.isOpen ? "block" : "none")};
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  imageUrl,
  content,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionItemContainer isOpen={isOpen}>
      <Header onClick={toggleAccordion}>
        <Image src={imageUrl} alt={title} />
      </Header>
      <Content isOpen={isOpen}>
        <p dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
    </AccordionItemContainer>
  );
};

export default AccordionItem;
