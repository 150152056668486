import React from "react";
import { Link } from "react-router-dom";

export default function ClocheLink() {
  return (
    <div className="services-btn my-[7vh] md:my-[10vh]">
      <Link onClick={() => window.scrollTo(0, 0)} to="/services">
        <img
          src="/images/services/home.png"
          alt="cloche"
          className="w-[80%] md:w-[60%] mx-auto"
        />
      </Link>
    </div>
  );
}
