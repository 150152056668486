import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { QuizQuestion } from "../QuizApp";

export default function QuizQuestions({
  onShowResult,
}: {
  onShowResult?: (input: QuizQuestion) => void;
}) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const input: QuizQuestion = {
      dinnerWith: form.dinnerWith.value,
      zodiac: form.zodiac.value,
      diet: form.diet.value,
      inKitchen: form.inKitchen.value,
      inHoliday: form.inHoliday.value,
      favorite: form.favorite.value,
    };
    console.log(input);
    onShowResult && onShowResult(input);
  };

  const [props, set] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
  }));

  const springs = useSpring({
    from: { opacity: 0, y: 100 },
    to: { opacity: 1, y: 0 },
    config: { duration: 1000 },
  });

  // make the animation start after some

  return (
    <animated.div
      style={props}
      className={" p-[20px] mb-[100px] flex flex-col items-center"}
      id="quiz-setup"
    >
      <img
        src="../../../images/quiz/logo.png"
        alt="Logo Bonsai Firenze Web Agency"
        width={80}
        height={80}
      />
      {/* form  con nome, congome, azienda*, mail, pizza o sushi (radio) */}
      <div className="w-[100%] mt-[20px]">
        <form
          className={"flex flex-col gap-[5px] w-full"}
          onSubmit={handleSubmit}
        >
          <div className="flex flex-row items-center justify-between">
            <div>
              {/* A CENA CON */}
              <label className="mt-3" htmlFor="dinnerWith">
                A CENA CON...
              </label>
              <label className="form-control mt-2 font-[400]">
                <input type="radio" name="dinnerWith" value="1" />
                Gerry Scotty
              </label>
              <label className="form-control font-[400]">
                <input type="radio" name="dinnerWith" value="2" />
                Matteo Berrettini
              </label>
              <label className="form-control font-[400]">
                <input type="radio" name="dinnerWith" value="3" />
                Luca Argentero
              </label>
            </div>
            <div className="pr-5">
              <animated.img
                src="../../../images/quiz/checco.png"
                alt="Logo Bonsai Firenze Web Agency"
                width={110}
                height={110}
                style={springs}
              />
            </div>
          </div>

          {/* SEGNO ZODIACALE */}
          <label className="mt-3" htmlFor="zodiac">
            SEGNO ZODIACALE
          </label>
          <label className="form-control mt-2 font-[400]">
            <input type="radio" name="zodiac" value="1" />
            Aria (Geme, Bila, Acqu)
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="zodiac" value="2" />
            Terra (Toro, Verg, Capri)
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="zodiac" value="3" />
            Acqua (Pes, Canc, Scor)
          </label>

          {/* SEI... */}
          <label className="mt-3" htmlFor="diet">
            SEI...
          </label>
          <label className="form-control mt-2 font-[400]">
            <input type="radio" name="diet" value="1" />
            Vegano
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="diet" value="2" />
            Onnivoro
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="diet" value="3" />
            Solo pesce
          </label>

          {/*IN CUCINA TI TROVIAMO... */}
          <label className="mt-3" htmlFor="inKitchen">
            IN CUCINA TI TROVIAMO...
          </label>
          <label className="form-control mt-2 font-[400]">
            <input type="radio" name="inKitchen" value="1" />
            Alla griglia
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="inKitchen" value="2" />
            Ai fornelli
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="inKitchen" value="3" />
            Sul divano
          </label>

          {/*IN VACANZA... */}
          <label className="mt-3" htmlFor="inHoliday">
            IN VACANZA...
          </label>
          <label className="form-control mt-2 font-[400]">
            <input type="radio" name="inHoliday" value="1" />
            In montagna
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="inHoliday" value="2" />
            Al mare
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="inHoliday" value="3" />
            In città
          </label>

          {/*RISTORANTE PREFERITO... */}
          <label className="mt-3" htmlFor="favorite">
            RISTORANTE PREFERITO...
          </label>
          <div className="flex flex-row justify-between">
            <div>
              <label className="form-control mt-2 font-[400]">
                <input type="radio" name="favorite" value="1" />
                Trattoria
              </label>
              <label className="form-control font-[400]">
                <input type="radio" name="favorite" value="2" />
                Stellato
              </label>
              <label className="form-control font-[400]">
                <input type="radio" name="favorite" value="3" />
                Bistrot
              </label>
            </div>
            <div className="pr-5">
              <animated.img
                src="../../../images/quiz/gelatino.png"
                alt="Logo Bonsai Firenze Web Agency"
                width={110}
                style={springs}
              />
            </div>
          </div>
          <button type="submit" className={"submit-button"} value="4">
            Tutti a tavola!
          </button>
        </form>
      </div>
    </animated.div>
  );
}
