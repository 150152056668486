import Slider from "react-slick";

const CUSTOMERS = [
  {
    name: "Blend",
    logo: "/images/customers/blend.png",
    url: "https://blend-ristorante.it/",
  },
  { name: "Gianno", logo: "/images/customers/gianno.png", url: "#" },
  {
    name: "Balena Gialla",
    logo: "/images/customers/balenagialla.png",
    url: "#",
  },
  { name: "Gurdulù", logo: "/images/customers/gurdulu.png", url: "#" },
  { name: "Time Calenzano", logo: "/images/customers/time.png", url: "#" },
];
export default function Customers() {
  var settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    arrows: false,
  };
  return (
    <div className="bg-green">
      <div className="bg-green py-4 mt-10 md:mx-[40px]">
        <h2 className="w-full text-center pink font-bold text-[1.3rem] md:text-[60px] mb-5">
          SPECIALITÀ DELLA CASA
        </h2>

        <Slider {...settings}>
          {CUSTOMERS.map((customer, index) => (
            <img src={customer.logo} alt={customer.name} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
