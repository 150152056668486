import React from "react";
import HeroSection from "./components/heroSection";
import ServicesOverview from "./components/ServicesOverview";
import FlagBanner from "./components/FlagBanner";
import Counters from "./components/Counters";
import Customers from "./components/Customers";
import ClocheLink from "./components/ClocheLink";
import Footer from "../../components/Footer";
import TestimonialCard, {
  TestimonialCardProps,
} from "./components/TestimonialCard";

const pages = [<div>Pagina 1</div>, <div>Pagina 2</div>, <div>Pagina 3</div>];

const testimonials: TestimonialCardProps[] = [
  {
    name: "Edoardo",
    review: `Team giovane e super disponibile!
 Attenti ai dettagli e tutte le mie esigenze. Super creativi e veloci nella risoluzione di ogni problema.
 Non potrei essere più contento!`,
  },
  {
    name: "Giulia",
    review: `Il restyling grafico del mio sito web ha superato le mie aspettative.
 La comunicazione è stata impeccabile, tutto il team ha seguito il progetto a
 360°
 Grazie mille!`,
  },
  { name: "Giovanni", review: `Sta scrivendo`, isTyping: true },
];

export default function HomeScreen() {
  return (
    <>
      <HeroSection />
      <ServicesOverview />
      <FlagBanner />
      <ClocheLink />
      <Counters />
      <Customers />

      <div className="flex flex-row flex-wrap items-center justify-center gap-[5vw] my-[50px]">
        {testimonials.map((testimonial) => (
          <TestimonialCard {...testimonial} />
        ))}
      </div>
      <Footer image="home" />
    </>
  );
}
