import Footer from "../../components/Footer";
import AccordionItem from "../../components/AccordionItem";

export default function ServicesScreen() {
  return (
    <div className="services-container">
      <div>
        <AccordionItem
          title="Accordion 1"
          imageUrl="images/services/accordion-head/1.png"
          content={`
            <ul>
              <li> Analisi demografica e geografica</li>
              <li> Analisi della concorrenza</li>
              <li> Analisi SWOT (punti di forza e debolezza)</li>
              <li> Definizione dimensione del mercato (valore e volume)</li>
              <li> Target di mercato</li>
              <li> Percezione del brand e comunicazione visiva</li>
              <li> Business plan</li>
            </ul>
          `}
        />
        <AccordionItem
          title="Accordion 2"
          imageUrl="images/services/accordion-head/2.png"
          content={`
            <ul>
              <li>Redazione manualistica operativa</li>
              <li>Consulenza su normative igieniche e di sicurezza</li>
              <li>Ottimizzazione labour cost</li>
              <li>Analisi food & beverage cost</li>
              <li>Selezione fornitori</li>
              <li>Valutazione pricing di vendita</li>
              <li>Menù engineering</li>
              <li>Presentazione piatti e linea comunicazione menù</li>
            </ul>
          `}
        />
        <AccordionItem
          title="Accordion 3"
          imageUrl="images/services/accordion-head/3.png"
          content={`
            <ul>
              <li>Attrattività aziendale e valorizzazione dei talenti</li>
              <li>Autorizzazioni sanitarie, di sicurezza</li>
              <li>Best practice sul lavoro</li>
              <li>Corsi di formazione tecnici e di approccio al cliente</li>
              <li>Redazione orario di lavoro</li>
              <li>Reportistica per valutazione delle risorse</li>
              <li>Team Building</li>
              <li>Riduzione del turnover</li>
            </ul>
          `}
        />
        <div className="mt-[40px]"></div>
        <AccordionItem
          title="Accordion 4"
          imageUrl="images/services/accordion-head/4.png"
          content={`
            <ul>
              <li>Gestione strategica dei profili digitali</li>
              <li>Campagne pubblicitarie e reportistica di riferimento</li>
              <li>Progettazione grafica online e offline</li>
              <li>Realizzazione shooting</li>
              <li>Editing di foto e video professionali</li>
              <li>Realizzazione web sites & mobile app</li>
              <li>Ottimizzazione SEO</li>
              <li>Gestione Google Ads e Google Analytics</li>
            </ul>
          `}
        />
      </div>
      <Footer image="ice" />
    </div>
  );
}
