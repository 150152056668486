import React from "react";

export interface TestimonialCardProps {
  name: string;
  review: string;
  isTyping?: boolean;
}

export default function TestimonialCard(props: TestimonialCardProps) {
  return (
    <div className=" testimonial w-[300px] h-[400px] px-10 py-10 md:w-[320px] md:h-[400px]  md:px-[1.5%] md:py-[2%] flex flex-col items-center justify-center md:justify-start gap-[20px] md:gap-[5px]">
      <div>
        <p className="md:text-[20px] text-[30px]">{props.name}</p>
      </div>

      <img src="/images/misc/stars.png" alt="" className="w-[50%]" />

      <div>
        <p className="md:text-[16px] text-[14px]  md:mt-[30px]">
          {props.review}
          {props.isTyping && (
            <span className="typing-dots">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          )}
        </p>
      </div>
    </div>
  );
}
