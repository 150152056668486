import React from "react";
import { animated, useSpring } from "@react-spring/web";
import { QuizSetupForm } from "../QuizApp";
export default function QuizSetup({
  onSetupQuix,
}: {
  onSetupQuix?: (input: QuizSetupForm) => void;
}) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Ottenere il valore selezionato da "food"
    const foodInput = form.querySelector(
      'input[name="food"]:checked'
    ) as HTMLInputElement;
    const foodValue = foodInput?.value || "";

    const input: QuizSetupForm = {
      name: form.fname.value,
      surname: form.surname.value,
      company: form.company.value,
      email: form.email.value,
      food: foodValue as "pizza" | "sushi",
    };
    onSetupQuix && onSetupQuix(input);
  };

  const [props, set] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
  }));

  return (
    <animated.div
      style={props}
      className={"h-min-[100vh] p-[20px] mb-[100px] flex flex-col items-center"}
      id="quiz-setup"
    >
      <img
        src="../../../images/quiz/logo.png"
        alt="Logo Bonsai Firenze Web Agency"
        width={80}
        height={80}
      />
      {/* form  con nome, congome, azienda*, mail, pizza o sushi (radio) */}
      <div className="w-[100%] mt-[20px]">
        <form
          className={"flex flex-col gap-[5px] w-full"}
          onSubmit={handleSubmit}
        >
          <label htmlFor="fname">NOME</label>

          <input
            className="border border-[#900] p-2 rounded-md w-full"
            type="text"
            id="fname"
            name="fname"
          />
          <label htmlFor="surname">COGNOME</label>
          <input type="text" id="surname" name="surname" />
          <label htmlFor="company">AZIENDA*</label>
          <input type="text" id="company" name="company" required />
          <label htmlFor="email">EMAIL*</label>
          <input type="email" id="email" name="email" required />
          <label className="mt-3" htmlFor="food">
            MA SOPRATUTTO...
          </label>
          <label className="form-control mt-2 font-[400]">
            <input type="radio" name="food" value="pizza" />
            Pizza
          </label>
          <label className="form-control font-[400]">
            <input type="radio" name="food" value="sushi" />
            Sushi
          </label>
          <button type="submit" className={"submit-button"}>
            Iniziamo
          </button>
        </form>
      </div>
    </animated.div>
  );
}
