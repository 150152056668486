import React from "react";
import CountUp from "react-countup";

export default function Counters() {
  return (
    <>
      <div className="bg-green h-[70%] w-[1.5px] absolute left-[50%]"></div>
      <div className=" flex  md:flex-row justify-between items-center mt-[30px]">
        <div className="md:text-left w-[80%] md:w-full pl-2 md:pl-20">
          <h1 className="fat-title text-[60px] md:text-[200px] pink">
            <CountUp end={1372} duration={5} />
          </h1>
          <p className="mx-auto text-[20px] md:text-[40px] md:mr-[30%] mt-2 md:mt-20 ">
            piatti fotografati
          </p>
        </div>

        <div className="">
          <img
            src="../../../images/icons/counters/camera.png"
            alt="hero"
            className="w-[200px] md:w-[500px]"
          />
        </div>
      </div>

      <div className=" flex flex-row-reverse justify-between items-center mt-[20px]">
        <div className="text-right w-[80%] md:w-[50%] pr-2 md:pr-20">
          <h1 className="fat-title text-[60px] md:text-[200px] pink">
            <CountUp end={23} duration={5} />
          </h1>
          <p className="mx-auto text-[20px] md:text-[40px] md:mt-12">
            bicchieri rotti
          </p>
        </div>

        <div className="">
          <img
            src="../../../images/icons/counters/pane.png"
            alt="hero"
            className="w-[200px] md:w-[350px]"
          />
        </div>
      </div>

      <div className=" flex  md:flex-row justify-between items-center mt-[30px]">
        <div className="md:text-left w-[80%] md:w-full pl-2 md:pl-20">
          <h1 className="fat-title text-[60px] md:text-[200px] pink">
            <CountUp end={469} duration={5} />
          </h1>
          <p className="mx-auto text-[20px] md:text-[40px] md:mr-[30%] md:mt-12">
            pranzi offerti
          </p>
        </div>

        <div className="">
          <img
            src="../../../images/icons/counters/bicchiere.png"
            alt="hero"
            className="w-[300px] md:w-[1000px]"
          />
        </div>
      </div>
    </>
  );
}
