import React from "react";

export default function ServicesOverview() {
  return (
    <>
      <div className=" flex  md:flex-row justify-between items-center mt-[30px]">
        <div className="md:text-left w-[80%] md:w-full pl-2 md:pl-20">
          <h1 className="fat-title text-[30px] md:text-[80px] pink">
            ESPERIENZA
          </h1>
          <p className="mx-auto text-[17px] md:text-[30px] md:mr-[30%] ">
            decennale nel settore della ristorazione, fondamentale per coltivare
            il successo dei tuoi progetti.
          </p>
        </div>

        <div className="">
          <img
            src="../../../images/icons/pala.png"
            alt="hero"
            className="w-[200px] md:w-[400px]"
          />
        </div>
      </div>

      <div className=" flex flex-row-reverse justify-between items-center mt-[20px]">
        <div className="text-right w-[80%] md:w-[50%] pr-2 md:pr-20">
          <h1 className="fat-title text-[30px] md:text-[80px] pink">
            CONSULENZA
          </h1>
          <p className="mx-auto text-[17px] md:text-[30px] mt-5">
            strategica e di marketing volta alla crescita delle tue idee e del
            tuo business online e offline.
          </p>
        </div>

        <div className="">
          <img
            src="../../../images/icons/semi.png"
            alt="hero"
            className="w-[300px] md:w-[500px]"
          />
        </div>
      </div>

      <div className=" flex  md:flex-row justify-between items-center mt-[30px]">
        <div className="md:text-left w-[80%] md:w-full pl-2 md:pl-20">
          <h1 className="fat-title text-[30px] md:text-[80px] pink">
            INNOVAZIONE
          </h1>
          <p className="mx-auto text-[17px] md:text-[30px] md:mr-[30%] ">
            digitale attraverso strumenti ad-hoc, utili per raccogliere i
            risultati e migliorare il tuo business.
          </p>
        </div>

        <div className="">
          <img
            src="../../../images/icons/pianta.png"
            alt="hero"
            className="w-[200px] md:w-[400px]"
          />
        </div>
      </div>
    </>
  );
}
